<template>
  <div class="loginPageBox">
    <h1 class="logoBox">
      <img :src="$localPicture+'logo/logo_black.png'" alt="logo">
    </h1>
    <div class="left">
      <p class="title">
        仓储管理 —— WMS云
      </p>
      <p class="describe">
        智能仓储管理中枢，全面适配灵活高效
      </p>
    </div>
    <div class="right">
      <!-- 这是注册的 -->
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="0"
        class="demo-ruleForm"
      >
        <el-form-item class="shfit" label="" prop="userCode">
          <el-input v-model="ruleForm.userCode" placeholder="请输入账号名称" />
        </el-form-item>
        <el-form-item label="" prop="password1">
          <el-input v-model="ruleForm.password1" type="password" placeholder="请输入登录密码" />
        </el-form-item>
        <el-form-item label="" prop="checkPassword">
          <el-input v-model="ruleForm.checkPassword" type="password" placeholder="请再次输入登录密码" />
        </el-form-item>
        <el-form-item label="" prop="cmpName">
          <el-input v-model="ruleForm.cmpName" placeholder="请输入公司名称" />
        </el-form-item>
        <el-form-item label="" prop="phoneNo">
          <el-input v-model="ruleForm.phoneNo" placeholder="请输入手机号码" />
        </el-form-item>
        <el-form-item style="margin-bottom:0;" label="" prop="phoneKaptcha">
          <el-input v-model="ruleForm.phoneKaptcha" class="verificationCode" placeholder="请输入验证码" />
          <span :class="['getCode', disabled ? 'disabledActive' : '']" @click="getCode">{{ codeTitle }}</span>
        </el-form-item>
        <div class="agreement">
          <el-checkbox v-model="agreeProtocol">
            请认真阅读此协议，勾选默认您同意协议内的所有内容
          </el-checkbox>
        </div>
        <el-form-item style="margin-bottom:0;" class="register-btn" label-width="0">
          <div class="flexBox">
            <el-button type="primary" class="determine" @click="submitForm('ruleForm')">
              确 定
            </el-button>
            <el-button class="reset" @click="resetForm('ruleForm')">
              重 置
            </el-button>
          </div>
        </el-form-item>
        <div class="jump">
          <router-link class="backLogin" :to="{ path: '/login' }">
            有账号？<span>去登录</span>
          </router-link>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { register, sendPhoneCode } from '@/api/login'
import { encrypt } from '@/unit/index'
import { rules, formPattern } from '@/unit/matchFormRegex'
export default {
  data() {
    var validateUserCode = (rule, value, callback) => {
      if (Number(value)) {
        callback(new Error('里面必须含有字母'))
      }
      callback()
    }
    var validatePass = (rule, value, callback) => {
      const reg = /^(?![\d]+$)(?![a-zA-Z]+$)(?![^\da-zA-Z]+$).{6,20}$/
      if (!reg.test(value)) {
        callback(new Error('字母、数字和其他符号至少包含2种'))
      } else {
        if (this.ruleForm.checkPassword !== '') {
          this.$refs.ruleForm.validateField('checkPassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.password1) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      loginOrRegister: false,
      agreeProtocol: false,
      codeTitle: '获取验证码',
      ruleForm: {
        cmpUnicode: '',
        cmpName: '',
        password1: '',
        userCode: '',
        phoneNo: '',
        checkPassword: '',
        phoneKaptcha: ''
      },
      disabled: false,
      rules: {
        kaptcha: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        cmpName: [{ required: true, message: '请输入公司名称', trigger: 'blur' },
          { min: 5, max: 50, message: '长度在 5 到 50 个字符', trigger: 'blur' }],
        phoneKaptcha: [{ required: true, message: '请输入验证码', trigger: 'blur' },
          { min: 4, max: 4, message: '长度在 4 个字符', trigger: 'blur' }],
        cmpUnicode: [...rules.unicode],
        userCode: [{ required: true, message: '请输入登录账号', trigger: 'blur' },
          { ...formPattern.letterNumberPlaces },
          { required: true, validator: validateUserCode, trigger: 'blur' },
          { min: 5, max: 25, message: '长度在 5 到 25 个字符', trigger: 'blur' }],
        phoneNo: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { ...formPattern.tel }
        ],
        password1: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字符', trigger: 'blur' },
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        password2: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ],
        checkPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 点击注册确认按钮
    submitForm(formName) {
      if (!this.agreeProtocol) {
        this.$message.error('请勾选协议框！')
        return
      }
      // if (this.ruleForm.code !== this.ruleForm.phoneKaptcha) {
      //   this.$message.error('验证码错误')
      //   return
      // }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.cmpRoleList = ['09']
          this.ruleForm.password = encrypt(this.ruleForm.password1)
          register(this.ruleForm, () => {
            this.$router.push({ path: '/login' })
            this.$message.success('成功！')
          })
        }
      })
    },
    // 点击获取验证码
    getCode() {
      if (this.disabled) return
      if (!this.ruleForm.phoneNo || this.ruleForm.phoneNo.length !== 11) {
        this.$message.error('请填写正确的手机号码')
        return
      }
      sendPhoneCode(this.ruleForm.phoneNo, res => {
        this.disabled = true
        let num = 60
        this.ruleForm.code = res.msg
        this.codeTitle = `${num}s`
        var timer = setInterval(() => {
          if (num <= 1) {
            this.disabled = false
            clearInterval(timer)
            this.codeTitle = '获取验证码'
            return
          }
          num--
          this.codeTitle = `${num}s`
        }, 1000)
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss">
.loginPageBox {
  position: relative;
  background: url(../../assets/image/bg5.png) no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .right {
    margin-left: 244px;
    width: 460px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 8px 15px 51px #c5c5c5;
    padding: 28px 0;
    .el-form {
      width: calc(100% - 70px);
      margin-left: 35px;
      .account {
        margin-bottom: 16px;
      }
      .password {
        margin-bottom: 30px;
      }
      .account2 {
        margin-bottom: 28px;
      }
      .password2 {
        margin-bottom: 42px;
      }
      .agreement{
        margin: 20px 0 8px;
      }
      .jump{
        font-size: 12px;
        margin-top: 8px;
        text-align: center;
        .backLogin{
          span{
            color: $pointClearColor;
          }
        }
      }
      .el-form-item__content {
        position: relative;

        .getCode{
          position: absolute;
          top: 6px;
          right: 12px;
          cursor: pointer;
          font-size: 12px;
          color: $mainBlue;
          z-index: 18;
        }
        .flexBox{
          display: flex;
          >.el-button{
            flex: 1;
            background-color: $pointClearColor;
            border: 1px solid $pointClearColor;
          }
          >.el-button:last-of-type{
            border: 1px solid $mainBlue;
            background-color: #fff;
            color: $mainBlue;
          }
        }

        .loginButton {
          display: inline-block;
          width: 100%;
          height: 46px;
          color: #fff;
          background-color: #e1251b;
          cursor: pointer;
          font-size: 15px;
          text-align: center;
          border: 0;
        }

        .registerButton {
          width: 100%;
          display: block;
          text-align: center;
          color: #e1251b;
          font-size: 12px;
          span {
            color: #999;
          }
        }

        img {
          position: absolute;
          top: 16px;
          z-index: 12;
          height: 18px;
          width: 18px;
        }

        .code {
          position: absolute;
          top: 19px;
          right: 24px;
          display: inline;
          z-index: 12;
          line-height: 1;
          font-size: 12px;
          color: #0089ff;
          cursor: pointer;
        }
        .imageCode {
          position: absolute;
          top: 0;
          right: 0;
          height: 48px;
          width: 120px;
          z-index: 223232;
        }

        .icon {
          left: 15px;
        }

        .icon2 {
          right: 89px;
        }

        .lookImg {
          right: 18px;
          cursor: pointer;
        }
      }

      .el-input__inner {
        // text-indent: 31px;
        height: 48px;
        background-color: #fff;
        border: 1px solid #f0f0f0 !important;
      }

      .el-input__inner:focus {
        border: 1px solid #f0f0f0 !important;
      }
    }

    .titleTab {
      padding: 40px 35px 35px;
      width: calc(100% - 70px);
      display: flex;

      span {
        position: relative;
        color: #747774;
        font-size: 16px;
        flex: 1;
        text-align: center;
        cursor: pointer;
      }

      span::after {
        position: absolute;
        left: 50%;
        transform: translatex(-50%);
        bottom: -11px;
        width: 24px;
        height: 2px;
        content: "";
      }

      .active {
        color: #e1251b;
      }

      .active::after {
        background-color: #e1251b;
      }
    }
  }
  .left {
    text-align: center;
    .describe {
      padding: 20px 0 30px;
      font-size: 30px;
      color: $mainFontColor;
    }
    .title {
      color: $mainBlue;
      font-size: 36px;
    }
  }
  .logoBox {
    position: absolute;
    top: 6vh;
    left: 3.2vw;
    z-index: 8;
    width: 230px;
    height: 58px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
